import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import { getNotificationsUser } from '../actions/notificationActions';
// import Swal from 'sweetalert2';
import { listOrders } from '../actions/orderActions';
// import { deleteOrder, listOrders } from '../actions/orderActions';
import { AlertMessage } from '../components/AlertMessage';
import { CustomDataTable } from '../components/CustomDataTable';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar';
import { ORDER_DELETE_RESET } from '../constants/orderConstants';
import removeAccents from '../utils/removeAccents';

export const OrderListScreen = () => {
    const navigate = useNavigate();
    const orderList = useSelector(state => state.orderList);
    const {loading, error, orders} = orderList;

    const [ordersFiltered, setOrdersFiltered] = useState([])
    const [minDate, setMinDate] = useState('')
    const [maxDate, setMaxDate] = useState('')

    const orderDelete = useSelector(state => state.orderDelete)    
    const { loading: loadingDelete, success: successDelete, error: errorDelete} = orderDelete;

    const dispatch = useDispatch();


    const columns = [
        {          
          name: 'REFERENCIA',
          cell: row => <span>{row.orderReference}</span>,
          selector: (row) => row.orderReference,
          sortable: true,          
        },
        {
          name: "CLIENTE",
          selector: (row) => removeAccents(row.user?.name),
          sortable: true,
          cell: (row) => <span onClick={() => navigate(`/user/${row.user._id}/edit`)} style={{cursor: 'pointer', color: '#1b67ff'}}>{row.user?.name}</span>,
        },
        {
          name: "FECHA",
          cell: row => <span>{row.createdAt.substring(0, 10)}</span>,
          selector: (row) => row.createdAt.substring(0, 10),
          sortable: true,
          right: true
        },
        {
          name: "TOTAL",
          selector: (row) => row.totalPrice,
          sortable: true,
          right: true,
          cell: (row) => <span>{row.totalPrice.toFixed(2)} €</span>,
        },
        {
          name: "PRODUCTOS",
          selector: (row) => row.orderItems.map((i, k) => (<div className='mb-1' key={k}>{removeAccents(i.name)} ({removeAccents(i.student.name)})</div>)),
          sortable: true,
          right: true,
          cell: (row) => <div>{row.orderItems.map((i, k) => (<div className='mb-1' key={k}>{i.name} (<span onClick={() => navigate(`/student/${i.student._id}/edit`)} style={{cursor: 'pointer', color: '#1b67ff'}}>{i.student.name}</span>)</div>))}</div>,
        },
        {
          name: "¿PAGADO?",
          cell: row => <span>{row.isPaid ? row.paidAt?.substring(0, 10): 'No'}</span>,
          sortable: true,
          right: true,
          selector: (row) => row.isPaid ? row.paidAt?.substring(0, 10) || '': 'No',
        },  
        {
          name: "MÉTODO DE PAGO",
          selector: (row) => row.isPaid ? row.paymentMethod: '-',
          sortable: true,
          right: true,    
          cell: (row) => <span>{row.isPaid ? row.paymentMethod: '-'}</span>,
        },
        {
          name: "",
          cell: row => 
            <div className="row">
                <div className="col-12">
                <i className="bi bi-eye-fill px-2" onClick={() => { navigate(`/order/${row._id}`); }} style={{fontSize: '1.4rem', cursor: 'pointer'}}></i>                
                </div>
            </div>,
          center: true,
        }
      ];

      useEffect(() => {
        dispatch({ type: ORDER_DELETE_RESET});
        dispatch(listOrders());
        dispatch(getNotificationsUser());
    }, [dispatch, successDelete])

    useEffect(() => {
      setOrdersFiltered(orders)
    }, [orders])

    useEffect(() => {
      let ordersCopy = orders
      if (minDate.trim() !== '') {
        ordersCopy = ordersCopy?.filter(o => new Date(o.paidAt) >= new Date(minDate))
      }
      if (maxDate.trim() !== '') {
        let dateObj = new Date(maxDate)
        ordersCopy = ordersCopy?.filter(o => new Date(o.paidAt) <= dateObj.setDate(dateObj.getDate() + 1))
      }
      setOrdersFiltered(ordersCopy || [])
    }, [minDate, maxDate, orders])

    return (
    <>
    <ToolBar titulo={"Pedidos"} hitos={[
        {titulo: "Inicio", enlace: "/" },
        {titulo: "Pedidos", enlace: "#" }]}/>
    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
      <div className="content flex-row-fluid" id="kt_content">        
        <div className="card card-flush">
            <div className="card-body">
              <div className='row mb-6'>
                <div className='col-6'>
                  <label className='text-muted'>Fecha de comienzo</label>
                  <input className='form-control' type="date" name='minDate' value={minDate} onChange={(e) => {setMinDate(e.target.value)}}/>
                </div>
                <div className='col-6'>
                  <label className='text-muted'>Fecha de fin</label>
                  <input className='form-control' type="date" name='maxDate' value={maxDate} onChange={(e) => {setMaxDate(e.target.value)}}/>
                </div>
              </div>
            {loadingDelete && (
                <LoadingBox variant={"primary"}/>
            )}
            {errorDelete && (
                <AlertMessage variant={"danger"} message={errorDelete}/>
            )}
            { loading ?  
                <LoadingBox variant={"primary"}/> : 
            error? 
                <AlertMessage variant={"danger"} message={error}/> : 
            (      
                <CustomDataTable data={ordersFiltered} columns={columns}/>
            )
            }
            </div>
        </div>
      </div>
    </div>
    </>
    )
}
