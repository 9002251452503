import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom';
import { createCategory, listCategories } from '../actions/categoryActions';
import { detailsProduct, updateProduct } from '../actions/productActions';
import { AlertMessage } from '../components/AlertMessage';
import { LoadingBox } from '../components/LoadingBox';
import { ToolBar } from '../components/ToolBar';
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants';
import Select from "react-select"
import { getSalesInfo, getSalesProduct } from '../actions/orderActions';
import { BarChart } from '../components/BarChart';
import { listAttributes } from '../actions/attributeActions';
import { listCourses } from '../actions/courseActions';
import { CustomDataTable } from '../components/CustomDataTable';
import Swal from 'sweetalert2';
import { CATEGORIE_CREATE_RESET } from '../constants/categorieConstants';
import { getNotificationsUser } from '../actions/notificationActions';
import removeAccents from '../utils/removeAccents';
import { listStudents } from '../actions/studentActions';

export const EditProductScreen = (props) => {

    const navigate = useNavigate();
    const params = useParams();
    const { id: productId } = params;

    const [name, setName] = useState('')
    const [sku, setSku] = useState('')
    const [price, setPrice] = useState('')
    const [iva, setIva] = useState(0);
    const [image, setImage] = useState('')
    const [countInStock, setCountInStock] = useState('')
    const [maxUnits, setMaxUnits] = useState('')
    const [brand, setBrand] = useState('')
    const [description, setDescription] = useState('')
    const [active, setActive] = useState('')
    const [alternativePayment, setAlternativePayment] = useState(false)
    const [billable, setBillable] = useState(false)
    const [oneTimePurchase, setOneTimePurchase] = useState(false)
    const [catalogMode, setCatalogMode] = useState(false)
    const [requiresAuthorization, setRequiresAuthorization] = useState(false)
    const [authorizationText, setAuthorizationText] = useState(null)

    const [categoryName, setCategoryName] = useState('')
    const [categoryDescription, setCategoryDescription] = useState('')
    const [categoryShow, setCategoryShow] = useState(true)

    const [chartSeries, setChartSeries] = useState([])
    const [chartCategories, setChartCategories] = useState([])

    const [currentCategories, setCategories] = useState([])

    const productDetails = useSelector(state => state.productDetails);
    const { loading, error, product } = productDetails;

    const productUpdate = useSelector(state => state.productUpdate);
    const { loading: loadingUpdate, error: errorUpdate, success: successUpdate } = productUpdate;

    const categoryCreated = useSelector(state => state.categoryCreate)

    const attributeList = useSelector((state) => state.attributeList);
    const { attributes } = attributeList;


    const categorieList = useSelector((state) => state.categorieList);
    const { loading: loadingCategory, error: errorCategory, categories } = categorieList;

    const courseList = useSelector((state) => state.courseList);
    const { loading: loadingCourses, error: errorCourses, courses } = courseList;

    const { loading: loadingStudents, error: errorStudents, students } = useSelector(state => state.studentList)

    const salesInfo = useSelector(state => state.orderSalesInfo)

    const orderSalesProduct = useSelector(state => state.orderSalesProduct)

    const [options, setOptions] = useState([])
    const selectedCategories = [];
    const [selectedOptions, setSelectedOptions] = useState([])

    const [attributesOptions, setAttributesOptions] = useState([])
    const [selectedAttribute, setSelectedAttribute] = useState([])
    const [selectedAttributeValues, setSelectedAttributeValues] = useState([])

    const [coursesOptions, setCoursesOptions] = useState([])
    const [selectedCourses, setSelectedCourses] = useState([])

    const [studentsOptions, setStudentsOptions] = useState([])
    const [selectedStudents, setSelectedStudents] = useState([])

    const [ordersFiltered, setOrdersFiltered] = useState([])
    const [minDate, setMinDate] = useState('')
    const [maxDate, setMaxDate] = useState('')

    const dispatch = useDispatch();
    

    const closeModalBtn = useRef();

    const columns = [
        {
            name: 'REFERENCIA',
            cell: row => <span>{row.orderReference}</span>,
            selector: (row) => removeAccents(row.orderReference),
            sortable: true,
        },
        {
            name: "PADRE",
            selector: (row) => removeAccents(row.user?.name),
            sortable: true,
            cell: (row) => <span>{row.user?.name}</span>,
        },
        {
            name: "ALUMNO",
            selector: (row) => removeAccents(JSON.stringify(row.orderItems.filter(item => item.product._id === productId))),
            sortable: true,
            cell: (row) => <div>{row.orderItems.filter(item => item.product._id === productId)?.map((i,k) => (<div className='mb-3' key={k}>{i?.student?.name}</div>))}</div>,
        },
        {
            name: "CURSO",
            selector: (row) => removeAccents(JSON.stringify(row.orderItems.filter(item => item.product._id === productId))),
            sortable: true,
            cell: (row) => <div>{row.orderItems.filter(item => item.product._id === productId)?.map((i, k) => (<div className='mb-3' key={k}>{i?.student?.course?.name}</div>))}</div>,
        },
        {
            name: "PRECIO",
            selector: (row) => removeAccents(JSON.stringify(row.orderItems.filter(item => item.product._id === productId))),
            sortable: true,
            cell: (row) => <div>{row.orderItems.filter(item => item.product._id === productId)?.map((i,k) => (<div className='mb-3' key={k}>{i?.price * i?.qty}€</div>))}</div>,
        },
        {
            name: "ATRIBUTOS",
            selector: (row) => removeAccents(JSON.stringify(row.orderItems.filter(item => item.product._id === productId))),
            sortable: true,
            cell: (row) => <span>{row.orderItems.filter(item => item.product._id === productId)?.map((i,k)=>(<div className='mb-3' key={k}>{i?.attributes[0]?.value}</div>))}</span>,
        },
        {
            name: "FECHAS",
            selector: (row) => removeAccents(JSON.stringify(row.orderItems.filter(item => item.product._id === productId))),
            sortable: true,
            cell: (row) => <div>{row.orderItems.filter(item => item.product._id === productId)?.map((i,k) => (<div className='mb-3' key={k}>{i?.dates?.join(',')}</div>))}</div>,
        },
        {
            name: "FECHA",
            cell: row => <span>{row.paidAt?.substring(0, 10) || ''}</span>,
            selector: (row) => removeAccents(row.paidAt?.substring(0, 10) || ''),
            sortable: true,
            center: true
        },
    ];

    useEffect(() => {
        if (categories) {
            setCategories(categories)
        }
    }, [categories])

    useEffect(()=>{
        let optionsToPut = []
        currentCategories.map((category) => (
            optionsToPut.push({ value: category._id, label: category.name })
        ))
        if (categoryCreated?.category) {
            optionsToPut.push({ value: categoryCreated.category._id, label: categoryCreated.category.name })
            setCategories([...currentCategories, categoryCreated.category])
            // options.push({ value: categoryCreated.category._id, label: categoryCreated.category.name })
            setSelectedOptions([...selectedOptions, { value: categoryCreated.category._id, label: categoryCreated.category.name }])
            // handleChange(options.find(option => option.value === categoryCreated.category._id))
            closeModalBtn.current.click()
            setCategoryName('')
            setCategoryDescription('')
            dispatch({type: CATEGORIE_CREATE_RESET})
        }
        setOptions(optionsToPut)
    }, [currentCategories, categoryCreated, selectedOptions, dispatch])

    useEffect(() => {
        let arrayValores = []
        if(product?.category) {
            product.category.map((productCategory) => (
                arrayValores.push({ value: productCategory._id, label: productCategory.name })
            ))
            setSelectedOptions(arrayValores)
            if (options.length < 1) {
                setOptions(arrayValores)
            }
        }
        let productCourses = []
        if (product?.courses) {
            product.courses.map((productCourse) => (
                productCourses.push({value: productCourse._id, label: productCourse.name})                
            ))
            setSelectedCourses(productCourses)
            if (coursesOptions.length < 1) {
                setCoursesOptions(productCourses)
            }
        }

        let productStudents = []
        if (product?.students) {
            product.students.map((productStudent) => (
                productStudents.push({value: productStudent._id, label: productStudent.name})                
            ))
            setSelectedStudents(productStudents)
            if (studentsOptions.length < 1) {
                setStudentsOptions(productStudents)
            }
        }
    }, [product])

    useEffect(() => {
        if (successUpdate) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Producto editado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
        }

        if (!product || product._id !== productId || successUpdate) {
            dispatch({ type: PRODUCT_UPDATE_RESET });
            dispatch(detailsProduct(productId));
        }
        else {
            setName(product.name);
            setSku(product.sku || '');
            setPrice(product.price);
            setImage(product.image);
            setCountInStock(product.countInStock);
            setMaxUnits(product.maxUnits)
            setBrand(product.brand);
            setDescription(product.description);
            setActive(product.active)
            setIva(product.iva)
            setAlternativePayment(product.alternativePayment ? product.alternativePayment : false)
            setBillable(product.billable ? product.billable : false)
            setOneTimePurchase(product.oneTimePurchase ? product.oneTimePurchase : false)
            setCatalogMode(product.catalogMode ? product.catalogMode : false)
            setRequiresAuthorization(product.requiresAuthorization || false)
            setAuthorizationText(product.authorizationText || null)
            dispatch(getSalesInfo(productId))
            dispatch(getSalesProduct(productId))
        }
        dispatch(listCategories());
        dispatch(listAttributes());
        dispatch(listCourses())
        dispatch(getNotificationsUser());
        dispatch(listStudents())
    }, [product, dispatch, productId, successUpdate, navigate])

    //Cuando obtenemos los atributos
    useEffect(() => {
        if (attributes) {
            let optionsToUse = [{ value: null, label: 'Sin atributo' }]
            attributes.forEach(attribute => {
                optionsToUse.push({ value: attribute._id, label: attribute.name })
            });
            if (product?.attributes?.length > 0) {
                let selectedAttr = optionsToUse.find(op => op.value === product.attributes[0].attribute)
                setSelectedAttribute(selectedAttr);
            } else {
                setSelectedAttribute(optionsToUse[0]);
            }
            setAttributesOptions(optionsToUse)

        }
    }, [attributes, product])


    //Cuando recibamos la informacion de ventas del producto
    useEffect(() => {
        if (salesInfo.sales) {
            const MONTHS = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
                "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
            ];
            let ordersMonths = []
            let ordersQty = []
            salesInfo.sales.forEach(order => {
                let createdAt = new Date(order.createdAt)
                let arrIndex = ordersMonths.indexOf(MONTHS[createdAt.getMonth()])
                if (arrIndex === -1) {
                    ordersMonths.push(MONTHS[createdAt.getMonth()])
                    ordersQty.push(order.orderItems.find(item => item.product === productId)?.qty)
                } else {
                    ordersQty[arrIndex] += order.orderItems.find(item => item.product === productId)?.qty
                }
            });
            setChartCategories(ordersMonths)
            setChartSeries(ordersQty)
        }
    }, [salesInfo, productId])

    useEffect(() => {
        if (attributes !== undefined && selectedAttribute.value && product) {
            let attributeOptionSelected = attributes.find(attribute => attribute._id === selectedAttribute.value)
            let attributeValues = []
            attributeOptionSelected.values.forEach(val => {
                let attributeCourses = []
                val.courses?.forEach(c => {
                    attributeCourses.push(courses?.find(co => co._id === c)?.name)
                })
                let attr = product.attributes?.find(attr => attr.value.value === val.value)
                if (attr) {
                    attributeValues.push({ label: val.value, date: val.date, selected: true, priceImpact: attr.priceImpact, stock: attr.stock, maxUnits: attr.maxUnits, courses: attributeCourses.join(', '), coursesIds: val.courses })
                } else {
                    attributeValues.push({ label: val.value, date: val.date, selected: false, priceImpact: 0, stock: 0, maxUnits: null, courses: attributeCourses.join(', '), coursesIds: val.courses })
                }
            });
            setSelectedAttributeValues(attributeValues)
        } else {
            setSelectedAttributeValues([])
        }
    }, [selectedAttribute, attributes, product, courses])

    //When courses are loaded
    useEffect(() => {
        if (courses) {
            let coursesOp = []
            courses.forEach(course => {
                coursesOp.push({value: course._id, label: course.name})
            })
            setCoursesOptions(coursesOp)
        }
    }, [courses])

    //When students are loaded
    useEffect(() => {
        if (students) {
            let studentsOp = []
            students.forEach(student => {
                studentsOp.push({value: student._id, label: student.name})
            })
            setStudentsOptions(studentsOp)
        }
    }, [students])

    const submitHandler = (e) => {
        e.preventDefault();

        if (name !== '') {
            if (description !== '') {
                if (price !== '') {
                    if (selectedOptions.length > 0) {
                        if (!requiresAuthorization || (authorizationText && authorizationText?.trim() !== '')) {
                            for (const category of currentCategories) {
                                for (const selectedOption of selectedOptions) {
                                    if (category._id === selectedOption?.value) {
                                        selectedCategories.push(category)
                                    }
                                }
                            }
                    
                            let productAttributtes = []
                    
                            if (selectedAttribute.value) {
                                selectedAttributeValues.forEach((val) => {
                                    if (val.selected) {
                                        productAttributtes.push({ attribute: selectedAttribute.value, value: {value: val.label, date: val.date, courses: val.coursesIds}, priceImpact: val.priceImpact, stock: val.stock, maxUnits: val.maxUnits })
                                    }
                                })
                    
                            }
                    
                            let productCourses = []
                            
                            for (const course of selectedCourses) {
                                productCourses.push(course.value)
                            }
    
                            let productStudents = []
                            
                            for (const student of selectedStudents) {
                                productStudents.push(student.value)
                            }
                    
                            dispatch(updateProduct({
                                _id: productId,
                                name,
                                sku,
                                price,
                                image,
                                category: selectedCategories,
                                brand,
                                countInStock: productAttributtes.length === 0 ? countInStock : null,
                                maxUnits: productAttributtes.length === 0 ? maxUnits : null,
                                description,
                                active,
                                iva,
                                attributes: productAttributtes,
                                courses: productCourses,
                                students: productStudents,
                                alternativePayment: alternativePayment,
                                billable: billable,
                                oneTimePurchase: oneTimePurchase,
                                catalogMode,
                                requiresAuthorization,
                                authorizationText: authorizationText
                            }))
                        } else {
                            Swal.fire({
                                position: 'center',
                                icon: 'error',
                                title: 'Introduzca un texto para la autorización',
                                showConfirmButton: true,
                                confirmButtonText: 'De acuerdo'
                            })
                        }
                    } else {
                        Swal.fire({
                            position: 'center',
                            icon: 'error',
                            title: 'Introduzca al menos una categoría',
                            showConfirmButton: true,
                            confirmButtonText: 'De acuerdo'
                        })
                    }
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Introduzca un precio para el producto',
                        showConfirmButton: true,
                        confirmButtonText: 'De acuerdo'
                    })
                }
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Introduzca una descripción para el producto',
                    showConfirmButton: true,
                    confirmButtonText: 'De acuerdo'
                })
            }
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Introduzca un nombre al producto',
                showConfirmButton: true,
                confirmButtonText: 'De acuerdo'
            })
        }
    }
    //Submit de formulario de crear categoria
    const submitCategoryHandler = (e) => {
        e.preventDefault()
        dispatch(createCategory({
            name: categoryName,
            description: categoryDescription,
            show: categoryShow,
        }))
    }

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [errorUpload, setErrorUpload] = useState('');

    const userSignin = useSelector(state => state.userSignin);
    const { userInfo } = userSignin;

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0];
        const bodyFormData = new FormData();
        bodyFormData.append('image', file);
        setLoadingUpload(true);
        try {
            const { data } = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/uploads/s3', bodyFormData, {
                headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${userInfo.token}` }
            })
            setImage(data);
            setLoadingUpload(false)
        } catch (error) {
            setErrorUpload(error.message);
            setLoadingUpload(false);
        }
    }

    const handleChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions)
    }

    const handleAttributeChange = (selectedOptions) => {
        setSelectedAttribute(selectedOptions)
    }

    const handleAttrCheck = (index) => {
        let newAttrValues = selectedAttributeValues.map((val, ind) => {
            if (ind === index) {
                return {...val, selected: !val.selected}
            } else {
                return val
            }
        })
        setSelectedAttributeValues(newAttrValues)
    }

    const handleAttrImpact = (index, value) => {
        let newAttrValues = selectedAttributeValues.map((val, ind) => {
            if (ind === index) {
                return {...val, priceImpact: value}
            } else {
                return val
            }
        })
        setSelectedAttributeValues(newAttrValues)
    }

    const handleAttrMaxUnits = (index, value) => {
        let newAttrValues = selectedAttributeValues.map((val, ind) => {
            if (ind === index) {
                return {...val, maxUnits: value}
            } else {
                return val
            }
        })
        setSelectedAttributeValues(newAttrValues)
    }

    const handleAttrStock = (index, value) => {
        let newAttrValues = selectedAttributeValues.map((val, ind) => {
            if (ind === index) {
                return {...val, stock: value}
            } else {
                return val
            }
        })
        setSelectedAttributeValues(newAttrValues)
    }

    const handleCoursesChange = (selectedValues) => {
        setSelectedCourses(selectedValues)
    }

    const handleStudentsChange = (selectedValues) => {
        setSelectedStudents(selectedValues)
    }

    const handleAlternativePaymentChange = (e) => {
        setAlternativePayment(e.target.checked)
    }

    const handleBillableChange = (e) => {
        setBillable(e.target.checked)
    }

    const handleOneTimePurchaseChange = (e) => {
        setOneTimePurchase(e.target.checked)
    }

    useEffect(() => {
        if (orderSalesProduct.sales) {
            setOrdersFiltered(orderSalesProduct.sales)
        }
    }, [orderSalesProduct.sales])

    useEffect(() => {
        let ordersCopy = orderSalesProduct.sales
        if (minDate.trim() !== '') {
          ordersCopy = ordersCopy?.filter(o => new Date(o.paidAt) >= new Date(minDate))
        }
        if (maxDate.trim() !== '') {
          let dateObj = new Date(maxDate)
          dateObj.setDate(dateObj.getDate() + 1)
          ordersCopy = ordersCopy?.filter(o => new Date(o.paidAt) <= dateObj)
        }
        setOrdersFiltered(ordersCopy || [])
      }, [minDate, maxDate, orderSalesProduct.sales])

    return (
        <>
            <ToolBar titulo={"Editar producto"} hitos={[
                { titulo: "Inicio", enlace: "/" },
                { titulo: "Productos", enlace: "/productlist" },
                { titulo: name, enlace: "#" }]} />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
                <div className="content flex-row-fluid" id="kt_content">
                    {loadingUpdate && (
                        <LoadingBox variant={"primary"} />
                    )}

                    {errorUpdate && (
                        <AlertMessage variant={"danger"} message={errorUpdate} />
                    )}

                    {loading ? (
                        <LoadingBox variant={"primary"} />
                    ) :
                        error ? (
                            <AlertMessage variant={"danger"} message={error} />
                        ) :
                            <>
                            <form autoComplete='off' id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row" onSubmit={userInfo.isAdmin ? submitHandler : ()=>{}}>

                                    <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Miniatura</h2>
                                                </div>
                                            </div>
                                            <div className="card-body text-center pt-0">
                                                <div className="image-input image-input-empty image-input-outline mb-3" data-kt-image-input="true">
                                                    <div className="image-input-wrapper w-150px h-150px" style={{ backgroundImage: `url('${image || product.image}')`, backgroundPosition: 'center', backgroundSize: 'contain' }} />
                                                    <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                                                        <i className="bi bi-x fs-2" />
                                                    </span>
                                                    <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                                                        <i className="bi bi-x fs-2" />
                                                    </span>
                                                </div>
                                                <div className="text-muted fs-7">Establezca la imagen en miniatura del producto. Solo se aceptan archivos de imagen *.png, *.jpg y *.jpeg</div>
                                            </div>
                                        </div>
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Estado</h2>
                                                </div>
                                                <div className="card-toolbar">
                                                    <div className={`rounded-circle ${active ? 'bg-success' : 'bg-danger'} w-15px h-15px`} id="kt_ecommerce_add_product_status" />
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                            <select className="form-select mb-2" data-control="select2" data-hide-search="true" data-placeholder="Select an option" id="kt_ecommerce_add_product_status_select" value={active} onChange={userInfo.isAdmin ? (e) => setActive(e.target.value) : ()=>{}}>
                                                    <option value={true}>Activado</option>
                                                    <option value={false}>Desactivado</option>
                                                </select>
                                                <div className="text-muted fs-7">Cambiar estado de producto.</div>
                                            </div>
                                        </div>
                                        <div className="card card-flush py-4">
                                            <div className="card-header">
                                                <div className="card-title">
                                                    <h2>Modo catálogo</h2>
                                                </div>
                                                <div className="card-toolbar">
                                                    <div className={`rounded-circle ${catalogMode ? 'bg-success' : 'bg-danger'} w-15px h-15px`} />
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                            <select className="form-select mb-2" data-control="select2" data-hide-search="true" data-placeholder="Select an option" value={catalogMode} onChange={userInfo.isAdmin ? (e) => setCatalogMode(e.target.value) : ()=>{}}>
                                                    <option value={true}>Activado</option>
                                                    <option value={false}>Desactivado</option>
                                                </select>
                                                <div className="text-muted fs-7">Cuando esté activado, no se podrá adquirir el producto.</div>
                                            </div>
                                        </div>
                                        <div className="card card-flush pt-4">
                                            <div className="card-header">
                                                <div className="card-title d-flex flex-column">
                                                    <div className="d-flex align-items-center">
                                                        <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">{chartSeries.length > 0 ? chartSeries[chartSeries.length - 1] : 0}</span>
                                                        {chartSeries.length > 1 ?
                                                            chartSeries[chartSeries.length - 1] > chartSeries[chartSeries.length - 2] ?
                                                                <span className="badge badge-success fs-base">
                                                                    <span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <rect opacity="0.5" x="13" y="6" width="13" height="2" rx="1" transform="rotate(90 13 6)" fill="black"></rect>
                                                                            <path d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z" fill="black"></path>
                                                                        </svg>
                                                                    </span>
                                                                    {chartSeries[chartSeries.length - 1] / chartSeries[chartSeries.length - 2] * 100 - 100} %
                                                                </span>
                                                                :
                                                                chartSeries[chartSeries.length - 1] < chartSeries[chartSeries.length - 2] ?
                                                                    <span className="badge badge-danger fs-base">
                                                                        <span className="svg-icon svg-icon-5 svg-icon-white ms-n1">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="black"></rect>
                                                                                <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="black"></path>
                                                                            </svg>
                                                                        </span>
                                                                        {chartSeries[chartSeries.length - 2] / chartSeries[chartSeries.length - 1] * 100 - 100} %
                                                                    </span>
                                                                    :
                                                                    <></>
                                                            : <></>}
                                                    </div>
                                                    <h2>Ventas de producto</h2>
                                                </div>
                                            </div>
                                            <div className="card-body p-0">
                                                <BarChart series={chartSeries || []} categories={chartCategories || []} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                        <div className='card'>
                                            <div className='card-body'>
                                                <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-n2">
                                                    <li className="nav-item">
                                                        <a className="nav-link pb-4 active text-dark" data-bs-toggle="tab" href="#kt_ecommerce_add_product_general">Detalles</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link pb-4 text-dark" data-bs-toggle="tab" href="#kt_ecommerce_add_product_advanced">Ventas</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                                            <div className="tab-content">
                                                <div className="tab-pane fade show active" id="kt_ecommerce_add_product_general">
                                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                                        <div className="card card-flush py-4">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>General</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="mb-10 fv-row">
                                                                    <label className="required form-label">Nombre del producto</label>
                                                                    <input required className="form-control mb-2" id="name" type="text" value={name} onChange={userInfo.isAdmin ? (e) => setName(e.target.value) : ()=>{}} />
                                                                    <div className="text-muted fs-7">Se requiere un nombre de producto que sea único.</div>
                                                                </div>
                                                                <div className="mb-10 fv-row">
                                                                    <label className="form-label">SKU del producto</label>
                                                                    <input className="form-control mb-2" id="sku" type="text" value={sku} onChange={userInfo.isAdmin ? (e) => setSku(e.target.value) : ()=>{}} />
                                                                    <div className="text-muted fs-7">Introduzca una la referencia del producto si lo tuviera</div>
                                                                </div>
                                                                <div className="mb-10 fv-row">
                                                                    <label className="required form-label">Descripción del producto</label>
                                                                    <textarea className="form-control mb-3" rows="5" data-kt-element="input" placeholder="Type a message" value={description} onChange={userInfo.isAdmin ? (e) => setDescription(e.target.value) : ()=>{}}></textarea>
                                                                </div>
                                                                <div className="mb-10 fv-row">
                                                                    <label className="required form-label">Categoría</label>
                                                                    {loadingCategory ? (<LoadingBox variant={"primary"} />) :
                                                                        errorCategory ? (<AlertMessage variant={"danger"} message={errorCategory} />) : (
                                                                            <Select isMulti isClearable options={options} onChange={userInfo.isAdmin ? handleChange : ()=>{}} value={selectedOptions} placeholder="Escoja la/s categorías del producto" />
                                                                        )
                                                                    }
                                                                    <button type="button" className=" mt-5 btn btn-light-primary btn-sm mb-10" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                                        <span className="svg-icon svg-icon-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black"></rect>
                                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="black"></rect>
                                                                            </svg>
                                                                        </span>
                                                                        Crear nueva categoría
                                                                    </button>
                                                                </div>
                                                                {
                                                                    process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD && process.env.REACT_APP_ALTERNATIVE_PAYMENT_METHOD !== '' &&
                                                                        <div className="fv-row mb-10">
                                                                            <label className='form-check form-switch form-check-custom form-check-solid'>
                                                                                <span className='form-check-label fw-bold me-4'>Pago alternativo</span>
                                                                                <input className='form-check-input' type='checkbox' checked={alternativePayment} onChange={userInfo.isAdmin ? handleAlternativePaymentChange : ()=>{}} />
                                                                            </label>
                                                                        </div>
                                                                }
                                                                <div className="fv-row">
                                                                    <label className='form-check form-switch form-check-custom form-check-solid'>
                                                                        <span className='form-check-label fw-bold me-4'>Facturable</span>
                                                                        <input className='form-check-input' type='checkbox' checked={billable} onChange={userInfo.isAdmin ? handleBillableChange : ()=>{}} />
                                                                    </label>
                                                                </div>
                                                                <div className="fv-row">
                                                                    <label className='form-check form-switch form-check-custom form-check-solid'>
                                                                        <span className='form-check-label fw-bold me-4'>Producto de compra única</span>
                                                                        <input className='form-check-input' type='checkbox' checked={oneTimePurchase} onChange={handleOneTimePurchaseChange} />
                                                                    </label>
                                                                </div>
                                                                {/* <div className="mb-10 fv-row">
                                                                    <label className="required form-label">Marca</label>
                                                                    <input className="form-control mb-2" id="brand" type="text" value={brand} onChange={userInfo.isAdmin ? (e) => setBrand(e.target.value) : ()=>{}} />
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="card card-flush py-4 mb-10">
                                                            <div className="card-header flex-row w-100">
                                                                <div className="card-title flex-row w-100">
                                                                    <div className='row w-100 align-items-center'>
                                                                        <div className='col-md-2 col-6'>
                                                                            <h2>Atributos</h2>
                                                                        </div>
                                                                        {/* <div className='col-md-4 col-6'>
                                                                        <button type="button" className="btn btn-light-primary btn-sm">
                                                                            <span className="svg-icon svg-icon-2">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                    <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="black"></rect>
                                                                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="black"></rect>
                                                                                </svg>
                                                                            </span>
                                                                            Añadir atributo
                                                                        </button>
                                                                    </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body py-0">
                                                                <div className="fv-row mb-2">
                                                                    {/* AQUI LOS ATRIBUTOS */}
                                                                    <Select options={attributesOptions} onChange={userInfo.isAdmin ? handleAttributeChange : ()=>{}} value={selectedAttribute} placeholder="Escoja lo/s atributo/s del producto" />
                                                                </div>
                                                                <div className='fv-row row'>
                                                                    {selectedAttributeValues.map((attr, ind) => (
                                                                        <div key={ind} className='col-md-4 col-12 px-2 py-4'>
                                                                            <div className='border p-4'>
                                                                                <h6>{attr.label}{attr.courses ? <span className='ms-4 text-muted'>{attr.courses}</span> : <></>}</h6>
                                                                                <label className='form-check form-switch form-check-custom form-check-solid'>
                                                                                <input className='form-check-input' type='checkbox' checked={attr.selected} onChange={userInfo.isAdmin ? () => { handleAttrCheck(ind)} : ()=>{}} />
                                                                                    <span className='form-check-label fw-bold text-gray-400'>Usar</span>
                                                                                </label>
                                                                                <label className='mt-3'>Impacto en el precio</label>
                                                                                <input type="number" min={0} step="any" className="form-control" value={attr.priceImpact} name="price-impact" onChange={userInfo.isAdmin ? (e) => { handleAttrImpact(ind, e.target.value)} : ()=>{}} />
                                                                                <label className='mt-3'>Stock</label>
                                                                                <input type="number" min={0} className="form-control" value={attr.stock} name="stock" onChange={userInfo.isAdmin ? (e) => { handleAttrStock(ind, e.target.value)} : ()=>{}} />
                                                                                <label className='mt-3'>Máximas unidades / pedido</label>
                                                                                <input type='number' min={1} step={1} className='form-control' value={attr.maxUnits || ''} name='maxUnits' onChange={(e) => {handleAttrMaxUnits(ind, e.target.value)}} />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                   
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card card-flush py-4 mb-10">
                                                        <div className="card-header flex-row w-100">
                                                            <div className="card-title flex-row w-100">
                                                                <div className='row w-100 align-items-center'>
                                                                    <div className='col-6'>
                                                                        <h2>Cursos relacionados</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="fv-row mb-2">
                                                            {
                                                                loadingCourses
                                                                ?
                                                                    (<LoadingBox variant={"primary"} />)
                                                                :
                                                                    errorCourses
                                                                    ?
                                                                        (<AlertMessage variant={"danger"} message={errorCourses} />)
                                                                    :
                                                                        (
                                                                            <Select isMulti options={coursesOptions} onChange={userInfo.isAdmin ? handleCoursesChange : ()=>{}} value={selectedCourses}  placeholder="Escoja los cursos del producto" />
                                                                        )
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card card-flush py-4 mb-10">
                                                        <div className="card-header flex-row w-100">
                                                            <div className="card-title flex-row w-100">
                                                                <div className='row w-100 align-items-center'>
                                                                    <div className='col-6'>
                                                                        <h2>Estudiantes relacionados</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="fv-row mb-2">
                                                            {
                                                                loadingStudents
                                                                ?
                                                                    (<LoadingBox variant={"primary"} />)
                                                                :
                                                                    errorStudents
                                                                    ?
                                                                        (<AlertMessage variant={"danger"} message={errorStudents} />)
                                                                    :
                                                                        (
                                                                            <Select isMulti options={studentsOptions} onChange={userInfo.isAdmin ? handleStudentsChange : ()=>{}} value={selectedStudents}  placeholder="Escoja los estudiantes del producto" />
                                                                        )
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='card card-flush py-4 mb-10'>
                                                        <div className='card-header flex-row w-100'>
                                                            <div className='card-title flex-row w-100'>
                                                                <div className='row w-100 align-items-center'>
                                                                    <div className='col-6'>
                                                                        <h2>Autorización</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='card-body pt-0'>
                                                            <div className='fv-row mb-2'>
                                                                <label className='form-check form-switch form-check-custom form-check-solid'>
                                                                    <span className='form-check-label fw-bold me-4'>Requiere autorización</span>
                                                                    <input className='form-check-input' type='checkbox' checked={requiresAuthorization} onChange={(e) => setRequiresAuthorization(e.target.checked)} />
                                                                </label>
                                                            </div>
                                                            <div className='fv-row mb-2'>
                                                                <label className='form-label'>Texto de autorización</label>
                                                                <textarea className='form-control' disabled={!requiresAuthorization} value={authorizationText || ''} onChange={(e) => setAuthorizationText(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card card-flush py-4 mb-10">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>Precio</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0 mb-8">
                                                            <div className="mb-10 fv-row">
                                                                <label className="required form-label">Precio (IVA inc.)</label>
                                                                <div className='input-group mb-2'>
                                                                    <input required className="form-control" aria-describedby="euro" id="price" type="text" value={price} onChange={userInfo.isAdmin ? (e) => setPrice(e.target.value.replace(',', '.')) : ()=>{}}/>
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text" id="euro">€</span>
                                                                    </div>
                                                                </div>
                                                                <div className="text-muted fs-7 mb-10">Establecer el precio del producto, IVA incluido.</div>
                                                            </div>
                                                            <div className="mb-10 fv-row">
                                                                <label className="form-label">IVA</label>
                                                                <select className="form-control mb-2" id="iva" value={iva} onChange={userInfo.isAdmin ? (e) => setIva(e.target.value) : ()=>{}}>
                                                                    <option value={0}>Sin IVA</option>
                                                                    <option value={4}>Súper reducido (4%)</option>
                                                                    <option value={10}>Reducido (10%)</option>
                                                                    <option value={21}>General (21%)</option>
                                                                </select>
                                                                <div className="text-muted fs-7">Establecer el IVA del producto.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {selectedAttribute.value === null ?
                                                        <div className="card card-flush py-4 mb-10">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>Inventario</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="mb-10 fv-row">
                                                                    <label className="form-label">Cantidad</label>
                                                                    <div className="d-flex gap-3">
                                                                    <input className="form-control mb-2" id="countInStock" type="text" value={countInStock || ''} onChange={userInfo.isAdmin ? (e) => setCountInStock(e.target.value) : ()=>{}}/>
                                                                    </div>
                                                                    <div className="text-muted fs-7">Introduce la cantidad del producto.</div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="mb-10 fv-row">
                                                                    <label className="form-label">Máximas unidades / pedido</label>
                                                                    <div className="d-flex gap-3">
                                                                    <input className="form-control mb-2" id="countInStock" type="text" value={maxUnits || ''} onChange={userInfo.isAdmin ? (e) => setMaxUnits(e.target.value) : ()=>{}}/>
                                                                    </div>
                                                                    <div className="text-muted fs-7">Máximas unidades del producto por pedido.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                    <div className="card card-flush py-4 mb-10">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2>Imagen</h2>
                                                            </div>
                                                        </div>
                                                        <div className="card-body pt-0">
                                                            <div className="fv-row mb-2">
                                                                <label htmlFor="imageFile" className="form-label">Eligir la imagen del producto</label>
                                                                <input className="form-control" required={false} type="file" id="imageFile" onChange={userInfo.isAdmin ? uploadFileHandler : ()=>{}}></input>
                                                                {loadingUpload && <LoadingBox variant={"primary"} />}
                                                                {errorUpload && <div>{errorUpload}</div>}
                                                            </div>
                                                            <div className="text-muted fs-7">Configure la galería multimedia del producto.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="kt_ecommerce_add_product_advanced" role="tab-panel">
                                                    <div className="d-flex flex-column gap-7 gap-lg-10">
                                                        <div className="card card-flush py-4">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h2>Ventas</h2>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className='row mb-6'>
                                                                    <div className='col-6'>
                                                                        <label className='text-muted'>Fecha de comienzo</label>
                                                                        <input className='form-control' type="date" name='minDate' value={minDate} onChange={userInfo.isAdmin ? (e) => {setMinDate(e.target.value)} : ()=>{}}/>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <label className='text-muted'>Fecha de fin</label>
                                                                        <input className='form-control' type="date" name='maxDate' value={maxDate} onChange={userInfo.isAdmin ? (e) => {setMaxDate(e.target.value)} : ()=>{}}/>
                                                                    </div>
                                                                </div>
                                                                <CustomDataTable data={ordersFiltered} columns={columns} />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                userInfo.isAdmin &&
                                                <div className="d-flex justify-content-end">
                                                    <Link to="/productlist" id="kt_ecommerce_add_product_cancel" className="btn btn-light me-5">Cancelar</Link>
                                                    <button type="submit" id="kt_ecommerce_add_product_submit" className="btn btn-primary">
                                                        <span className="indicator-label">Actualizar</span>
                                                        <span className="indicator-progress">Espere por favor...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </form>
                                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-xl">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="staticBackdropLabel">Crear categoría</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">
                                            <form autoComplete='off' className="form w-100 row" id="kt_sign_in_form" onSubmit={userInfo.isAdmin ? submitCategoryHandler : ()=>{}}>
                                                    <div className="mb-10 fv-row">
                                                        <label className="required form-label">Nombre de la categoría</label>
                                                        <input className="form-control mb-2" id="name" type="text" value={categoryName} onChange={userInfo.isAdmin ? (e) => setCategoryName(e.target.value) : ()=>{}} />
                                                        <div className="text-muted fs-7">Se requiere un nombre de categoría que sea único.</div>
                                                    </div>
                                                    <div className="mb-10 fv-row">
                                                        <label className="required form-label">Descripción</label>
                                                        <textarea className="form-control mb-3" rows="5" data-kt-element="input" placeholder="Type a message" value={categoryDescription} onChange={userInfo.isAdmin ? (e) => setCategoryDescription(e.target.value) : ()=>{}}></textarea>
                                                    </div>
                                                    <div className="mb-10 fv-row">
                                                        <label className="required form-label">Estado</label>
                                                        <select className="form-select mb-2" data-control="select2" data-hide-search="true" data-placeholder="Select an option" id="kt_ecommerce_add_product_status_select" value={categoryShow} onChange={(e) => setCategoryShow(e.target.value)}>
                                                            <option value={true}>Mostrada</option>
                                                            <option value={false}>Oculta</option>
                                                        </select>
                                                        <div className="text-muted fs-7">Mostrar/Ocultar categoría.</div>
                                                    </div>
                                                    <div className="text-end">
                                                        <button type="submit" id="kt_sign_in_submit" className="btn btn-primary me-1" disabled={categoryCreated.loading ? 'disabled' : ''}>
                                                            {
                                                                categoryCreated.loading ? (
                                                                    <span className="indicator-progress">Espere por favor...
                                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                                ) :
                                                                    (<span className="indicator-label">Crear</span>)
                                                            }
                                                        </button>
                                                        <button ref={closeModalBtn} type="button" id="closeModal" className="btn btn-secondary ms-1" data-bs-dismiss="modal">Cerrar</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </>
    )
}